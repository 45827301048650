import React from "react";
import {
    TextInput,
    PasswordInput,
    Paper,
    Title,
    Container,
    Button,
    Alert,
    Flex,
} from "@mantine/core";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { IconAlertCircle } from "@tabler/icons-react";
import { Helmet } from "react-helmet-async";
import FormattedLink from "./FormattedLink";

interface AuthComponentProps {
    onSubmit: (email: string, password: string) => Promise<void>;
    error: boolean;
    loading: boolean;
}

export default function AuthComponent(props: AuthComponentProps) {
    const { onSubmit, error, loading } = props;

    const form = useForm({
        initialValues: {
            email: "",
            password: "",
        },

        validate: {
            email: isEmail("Please enter valid email address"),
            password: isNotEmpty("This field is required"),
        },

        validateInputOnChange: true,
        validateInputOnBlur: true,
    });

    const formOnSubmit = form.onSubmit((values) =>
        onSubmit(values.email, values.password)
    );

    return (
        <>
            <Helmet>
                <title>GenPhlo - Login</title>
            </Helmet>
            <Container size={420} my={40}>
                <Title
                    align="center"
                    sx={(theme) => ({
                        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
                        fontWeight: 900,
                    })}
                >
                    Welcome back!
                </Title>
                <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                    <form noValidate onSubmit={formOnSubmit}>
                        {error && (
                            <Alert
                                icon={<IconAlertCircle size="1rem" />}
                                color="red"
                                title="Login failed"
                                mb="sm"
                                data-qa="login_errorAlert"
                            >
                                Please review your username and password, or use
                                our <b>Forgotten Password</b> link.
                            </Alert>
                        )}

                        <TextInput
                            label="Email"
                            placeholder="you@eql.ai"
                            required
                            data-qa="login_emailField"
                            {...form.getInputProps("email")}
                        />

                        <PasswordInput
                            label="Password"
                            placeholder="Your password"
                            required
                            mt="md"
                            data-qa="login_passwordField"
                            {...form.getInputProps("password")}
                        />

                        <Button
                            type="submit"
                            loading={loading}
                            disabled={!form.isValid()}
                            fullWidth
                            mt="xl"
                            data-qa="login_submitButton"
                        >
                            Sign in
                        </Button>

                        <Flex justify="center" mt="md">
                            <FormattedLink
                                size="sm"
                                href="/forgot-password"
                                dataqa="login_forgotPasswordLink"
                            >
                                Forgotten Password?
                            </FormattedLink>
                        </Flex>
                    </form>
                </Paper>
            </Container>
        </>
    );
}

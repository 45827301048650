import { User, signOut, signInWithEmailAndPassword } from "firebase/auth";
import { StateCreator } from "zustand";
import backend from "../config/DatabaseConfig";

export interface AuthSlice {
    isAuthenticated: boolean;
    user: User | null;
    /**
     * Sign in with email and password
     * @param {string} email
     * @param {string} password
     */
    login(email: string, password: string): Promise<void>;
    /**
     * Sign out
     */
    logout(): Promise<void>;
}

export const createAuthSlice: StateCreator<AuthSlice> = (set, get) => ({
    login: async (email: string, password: string) => {
        try {
            const userCredential = await signInWithEmailAndPassword(
                backend.AUTH,
                email,
                password
            );
            set({ isAuthenticated: true, user: userCredential.user });
        } catch (error) {
            console.error(error);
        }
    },
    logout: async () => {
        try {
            await signOut(backend.AUTH);
            set({ isAuthenticated: false, user: null });
        } catch (error) {
            console.error(error);
        }
    },
    isAuthenticated: false,
    user: null,
});

import React from "react";
import { useSetState } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import ForgotPassword from "./ForgotPassword";
import { resetPassword } from "../../services/callables/resetPassword";

const ForgotPasswordContainer = () => {
    const [state, setState] = useSetState({ loading: false });

    const handleSubmit = async (email: string): Promise<void> => {
        try {
            setState({ loading: true });

            await resetPassword({ email });

            notifications.show({
                title: "Success!",
                message: `A password reset email has been sent to ${email}`,
                color: "green",
                icon: <IconCheck />,
            });
        } catch (e) {
            console.log(e);
            notifications.show({
                title: "Action failed",
                message: "Please check your email address and try again",
                color: "red",
                icon: <IconX />,
            });
        } finally {
            setState({ loading: false });
        }
    };

    return <ForgotPassword onSubmit={handleSubmit} loading={state.loading} />;
};

export default ForgotPasswordContainer;

import React from "react";
import { useEffect } from "react";
import AuthComponent from "../components/AuthComponent";
import { useBoundStore } from "../store";
import { useNavigate } from "react-router-dom";
import { useSetState } from "@mantine/hooks";

const AuthLayout = () => {
    const { login, isAuthenticated } = useBoundStore();

    const navigate = useNavigate();

    const [state, setState] = useSetState({ loading: false, error: false });

    const handleSignIn = async (
        email: string,
        password: string
    ): Promise<void> => {
        try {
            setState({ loading: true });

            await login(email, password);

            if (!isAuthenticated) {
                setState({ error: true });
            }
        } catch (e) {
            setState({ error: true });
            console.log(e);
        } finally {
            setState({ loading: false });
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        navigate("/dashboard");

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <AuthComponent
            onSubmit={handleSignIn}
            error={state.error}
            loading={state.loading}
        />
    );
};

export default AuthLayout;
